import React, { useMemo } from 'react'
import { graphql } from 'gatsby'

import { GraphQLErrorList } from '../components/Graphql-error-list'
import { Post } from '../components/Post'
import { SEO } from '../components/SEO'
import { LayoutContainer } from '../containers/Layout'

import { toPlainText } from '../lib/helpers'

export const query = graphql`
  query PostTemplateQuery($id: String!) {
    allHighlights: allSanityMapbox {
      edges {
        node {
          id
          caption
          location {
            lat
            lng
            alt
          }
        }
      }
    }
    post: sanityPost(id: { eq: $id }) {
      location
      coffee
      highlights: map {
        id
        caption
        location {
          lat
          lng
        }
      }
      categories {
        _id
        title
      }
      id
      publishedAt
      location
      images {
        ...SanityImage
      }
      title
      _rawBody(resolveReferences: { maxDepth: 5 })
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      note {
        title
        images {
          ...SanityImage
        }
        _rawBody
      }
    }
  }
`

const PostTemplate = ({ data, errors }) => {
  const post = data && data.post
  const allHighlights = useMemo(() => {
    return data.allHighlights.edges.map(({ node: highlight }, mapId) => {
      return {
        ...highlight,
        mapId
      }
    })
  })
  const image =
    post.images &&
    post.images.find(image => image.__typename === 'SanityMainImage')

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }

  return (
    <LayoutContainer>
      {post && (
        <SEO
          title={post.title || 'Untitled'}
          description={toPlainText(post._rawExcerpt)}
          image={image}
        />
      )}

      {post && <Post {...post} allHighlights={allHighlights} />}
    </LayoutContainer>
  )
}

export default PostTemplate
